

import gsap from "~/mixins/gsap";
export default {
    props : ['blok'],
    mixins : [gsap],
    data() {
        return {
            categories : null,
            categoriesToList : null,
            alreadyOnRequest : false,
            categoryLevel: [],
            changingCategory: false,
            fadingIn: false
        }
    },
    mounted() {
        this.getCategories();
    },
    watch: {
    categoryLevel(){
        this.fadeOut()
    }
    },
    methods: {
        fadeOut(){
            this.changingCategory = true;
            var els = this.$el.querySelectorAll('.systemHorizontalSliderComponentsCategory');
            this.gsap.fromTo(els,{x:0, opacity: 1},{x: -20, opacity: 0, stagger: 0.1, onComplete: this.handleCategories});
        },
        handleCategories(){
            if(this.categoryLevel && this.categoryLevel.length == 0){
                this.fadingIn = true;
                this.categoriesToList = this.categories;
                this.$nextTick(() => {
                    var els = this.$el.querySelectorAll('.systemHorizontalSliderComponentsCategory');
                    this.gsap.fromTo(els,{x:-20, opacity: 0},{x: 0, opacity: 1, stagger: 0.1, onComplete: () => {this.fadingIn = false; this.changingCategory = false}});
                })
                return;
            } 
            var parentUuid = this.categoryLevel.at(-1);
            this.findCategory(parentUuid,this.categories);
        },
        findCategory(parentUuid,categories){
            var found = null;
            for(var i in categories){
                if(categories[i].uuid == parentUuid){
                    found = categories[i].children
                    break;
                }else{
                    this.findCategory(parentUuid,categories[i].children);
                }
            }
            if(found){
                this.fadingIn = true
                this.categoriesToList = found;
                this.$nextTick(() => {
                    var els = this.$el.querySelectorAll('.systemHorizontalSliderComponentsCategory');
                    this.gsap.fromTo(els,{x:-20, opacity: 0},{x: 0, opacity: 1, stagger: 0.1, onComplete: () => {this.fadingIn = false; this.changingCategory = false}});
                })
                

            }
        },
        changeCategory(uuid){
            this.categoryLevel.push(uuid);
        },
        getCategories(){
            if(!this.categories && !this.alreadyOnRequest){
            this.alreadyOnRequest = true;
            this.$axios.get("/storyblok/hpstories",{params: {flushcache: 'yes', fulltree: 'yes'}}).then(axRes => {
                this.categories = axRes.data.categories;
                this.handleCategories();
                this.alreadyOnRequest = false;
            }).catch(axErr => {
                console.log(axErr.stack);
                this.alreadyOnRequest = false;
            })
                
            }
        }
    },
}
